import React, { useState, useEffect } from "react";
import { IconButton } from "@mui/material";
import { X } from "phosphor-react";

// Components
import Shimmer from "./../../components/Card/Shimmer";
import Card from "./../../components/Card/index";

import styles from "./Search.module.scss";
import { getCoin } from "../../services/api";

const Index = () => {
  const [coins, setCoins] = useState([]);
  const [search, setSearch] = useState("");
  useEffect(() => {
    const fetchAPI = async () => {
      const data = await getCoin({ percentage: "24h", Currency: "usd" });
      setCoins(data);
    };
    fetchAPI();
  }, []);

  const SearchHandler = (event) => {
    setSearch(event.target.value);
  };

  const searchCoins = coins.filter((coin) =>
    coin.name.toLowerCase().includes(search.toLowerCase())
  );
  return (
    <>
      <div className={styles.searchContainer}>
        <input
          className={styles.searchInput}
          type="text"
          name="search"
          placeholder="Search Cryptocurrencies"
          onChange={SearchHandler}
          value={search}
        />
        {search.length > 0 && (
          <IconButton
            onClick={() => setSearch("")}
            className={styles.searchClear}
          >
            <X size={24} />
          </IconButton>
        )}
      </div>

      {coins.length > 0 ? (
        searchCoins.map((coin) => <Card key={coin.id} data={coin} />)
      ) : (
        <div>
          <Shimmer />
          <Shimmer />
          <Shimmer />
          <Shimmer />
          <Shimmer />
          <Shimmer />
        </div>
      )}
    </>
  );
};

export default Index;
