import React from "react";
import styles from "./Card.module.scss";
import { Skeleton } from "@mui/material";

const Shimmer = () => {
  return (
    <>
      <div className={styles.cardShimmer}>
        <div className={styles.leftSection}>
          <Skeleton
            variant="circular"
            sx={{ bgcolor: "grey.900", marginRight: "12px" }}
            height="50px"
            width="50px"
          />
          <div>
            <Skeleton
              variant="text"
              sx={{ bgcolor: "grey.900" }}
              height={30}
              width={100}
            />

            <Skeleton
              variant="text"
              sx={{ bgcolor: "grey.900" }}
              height={24}
              width={60}
            />
          </div>
        </div>
        <div className={styles.rightSection}>
          <Skeleton
            variant="text"
            sx={{ bgcolor: "grey.900" }}
            height={30}
            width={100}
          />

          <Skeleton
            variant="text"
            sx={{ bgcolor: "grey.900" }}
            height={24}
            width={60}
          />
        </div>
      </div>
    </>
  );
};

export default Shimmer;
