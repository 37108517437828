import React from "react";
import Search from "./../../components/Search/index";

const Index = () => {
  window.document.title = "Search";
  return (
    <>
      <Search />
    </>
  );
};

export default Index;
