import React, { useContext } from "react";
import { CardActionArea } from "@mui/material";
import { TrendingContext } from "../../pages/Landing/Landing";
import styles from "./SliderCard.module.scss";
import { ArrowUp, ArrowDown } from "phosphor-react";

const Index = () => {
  const SliderContext = useContext(TrendingContext);
  const { name, symbol, current_price, image, price_change_percentage_24h } =
    SliderContext;

  const CurrencySaved = localStorage.getItem("Currency");
  if (!CurrencySaved) {
    console.log("HH");
  }
  return (
    <>
      <div className={styles.card}>
        <CardActionArea className={styles.CardActionArea}>
          <div className={styles.topSection}>
            <div>
              <p>{name}</p>
              <p>{symbol.toUpperCase()}</p>
            </div>
            <img src={image} alt="img" />
          </div>
          <div className={styles.bottomSection}>
            <div className={styles.priceChanges}>
              <p>
                {!CurrencySaved ? "$" : CurrencySaved === "usd" && "$"}
                {CurrencySaved === "eur" && "€"}
                {CurrencySaved === "jpy" && "¥"}{" "}
                {Number(current_price).toLocaleString()}
              </p>
            </div>
            <div
              className={`${styles.percentChange} ${
                price_change_percentage_24h > 0
                  ? styles.percentChangeUP
                  : styles.percentChangeDOWN
              }`}
            >
              {price_change_percentage_24h > 0 ? (
                <ArrowUp size={16} />
              ) : (
                <ArrowDown size={16} />
              )}
              <p>{price_change_percentage_24h.toFixed(2)}</p>
            </div>
          </div>
        </CardActionArea>
      </div>
    </>
  );
};

export default Index;
