import React from "react";
import { IconButton, Slider } from "@mui/material";
import { X } from "phosphor-react";
import styles from "./Modal.module.scss";

const Index = ({
  id,
  open,
  setOpen,
  animation,
  setAnimation,
  menuClose,
  data,
}) => {
  const closeHandler = () => {
    setTimeout(() => {
      setOpen(false);
    }, 600);
    setTimeout(() => {
      setAnimation(false);
    }, 200);
  };
  const CurrencySaved = localStorage.getItem("Currency");
  return (
    <>
      <div className={`${styles.modal} ${open && styles.show_Overlay}`}>
        <div
          className={`${styles.modalCard} ${open && styles.show_Overlay_card}`}
          name={menuClose.join(" ")}
        >
          <div className={styles.cardHeader}>
            <div className={styles.cardHeaderRight}>
              <img src={data.image} alt={data.name} />
              <div>
                <h3>{data.name && data.name}</h3>
                <p>{data.symbol && data.symbol.toUpperCase()}</p>
              </div>
            </div>
            <IconButton onClick={closeHandler} className={styles.closebtn}>
              <X size={24} />
            </IconButton>
          </div>
          <div className={styles.cardContent}>
            <div className={styles.RangeSlider}>
              <Slider
                value={data.current_price}
                min={data.low_24h}
                step={1}
                max={data.high_24h}
                valueLabelDisplay="on"
                aria-labelledby="non-linear-slider"
              />
              <div className={styles.Slider}>
                <p>
                  {!CurrencySaved ? "$" : CurrencySaved === "usd" && "$"}
                  {CurrencySaved === "eur" && "€"}
                  {CurrencySaved === "jpy" && "¥"}{" "}
                  {data.low_24h && data.low_24h.toLocaleString()}
                </p>
                <p>
                  {data.price_change_percentage_24h &&
                    data.price_change_percentage_24h.toFixed(2)}
                  %
                </p>
                <p>
                  {!CurrencySaved ? "$" : CurrencySaved === "usd" && "$"}
                  {CurrencySaved === "eur" && "€"}
                  {CurrencySaved === "jpy" && "¥"}{" "}
                  {data.high_24h && data.high_24h.toLocaleString()}
                </p>
              </div>
            </div>
            {data.market_cap ? (
              <div className={styles.titleSection}>
                <p>Market Cap</p>
                <p>
                  {!CurrencySaved ? "$" : CurrencySaved === "usd" && "$"}
                  {CurrencySaved === "eur" && "€"}
                  {CurrencySaved === "jpy" && "¥"}{" "}
                  {data.market_cap && data.market_cap.toLocaleString()}
                </p>
              </div>
            ) : (
              ""
            )}

            {data.total_volume ? (
              <div className={styles.titleSection}>
                <p>24 Hour Trading Vol</p>
                <p>
                  {!CurrencySaved ? "$" : CurrencySaved === "usd" && "$"}
                  {CurrencySaved === "eur" && "€"}
                  {CurrencySaved === "jpy" && "¥"}{" "}
                  {data.total_volume && data.total_volume.toLocaleString()}
                </p>
              </div>
            ) : (
              ""
            )}

            {data.datacirculating_supply ? (
              <div className={styles.titleSection}>
                <p>Circulating Supply</p>
                <p>
                  {data.datacirculating_supply &&
                    data.circulating_supply.toLocaleString()}
                </p>
              </div>
            ) : (
              ""
            )}

            {data.total_supply ? (
              <div className={styles.titleSection}>
                <p>Total Supply</p>
                <p>{data.total_supply && data.total_supply.toLocaleString()}</p>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div
          className={`${styles.overlay} ${open && styles.show_Overlay}`}
        ></div>
      </div>
    </>
  );
};

export default Index;
