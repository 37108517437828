import axios from "axios";
import queryString from "query-string";

const BASE_URL =
  "https://api.coingecko.com/api/v3/coins/markets?sparkline=false&order=market_cap_desc&per_page=250&page=1";
const parsed = queryString.parse(BASE_URL);

const getCoin = async ({ percentage, Currency }) => {
  parsed.price_change_percentage = percentage;
  parsed.vs_currency = Currency;
  const response = await axios.get(
    `${BASE_URL}&vs_currency=${parsed.vs_currency}&price_change_percentage=${parsed.price_change_percentage}`
  );
  return response.data;
};

export { getCoin };
