import React, { useState } from "react";
import { CardActionArea } from "@mui/material";
//
import Modal from "./../Modal/index";
import styles from "./Card.module.scss";

// ICONS
import { TrendDown, TrendUp } from "phosphor-react";

const Index = (props) => {
  const {
    id,
    symbol,
    name,
    image,
    current_price,
    price_change_percentage_24h,
  } = props.data;

  const [mid, setMId] = useState(null);
  const [modalopen, setModalopen] = useState(false);
  const [modalanimation, setAnimation] = useState(false);

  let menuClose = [];
  if (!modalanimation) {
    menuClose.push("HeaderPanel_CloseMenuPanel__2l3rS");
    document.body.style.overflow = "";
  } else if (modalanimation) {
    document.body.style.overflow = "hidden";
  }

  const openHandler = () => {
    setTimeout(() => {
      setModalopen(true);
      setAnimation(true);
    }, 100);
    setMId(id);
  };

  const CurrencySaved = localStorage.getItem("Currency");
  return (
    <>
      <Modal
        open={modalopen}
        setOpen={setModalopen}
        animation={modalanimation}
        setAnimation={setAnimation}
        menuClose={menuClose}
        data={props.data}
        id={mid}
      />
      <div className={styles.card}>
        <CardActionArea onClick={openHandler} className={styles.CardActionArea}>
          <div className={styles.leftSection}>
            <img src={image} alt="img" />
            <div>
              <p className={styles.cardName}>{name}</p>
              <p className={styles.cardSymbol}>{symbol.toUpperCase()}</p>
            </div>
          </div>
          <div className={styles.rightSection}>
            <p className={styles.cardName}>
              {!CurrencySaved ? "$" : CurrencySaved === "usd" && "$"}
              {CurrencySaved === "eur" && "€"}
              {CurrencySaved === "jpy" && "¥"}{" "}
              {Number(current_price).toLocaleString()}
            </p>
            <div
              className={`${styles.cardPercentAge} ${
                price_change_percentage_24h > 0
                  ? styles.cardPercentagePos
                  : styles.cardPercentageNeg
              }`}
            >
              {price_change_percentage_24h > 0 ? (
                <TrendUp size={16} />
              ) : (
                <TrendDown size={16} />
              )}

              <p
                className={`${styles.cardSymbol} ${
                  price_change_percentage_24h > 0
                    ? styles.cardPercentagePos
                    : styles.cardPercentageNeg
                }`}
              >
                {price_change_percentage_24h.toFixed(2)} %
              </p>
            </div>
          </div>
        </CardActionArea>
      </div>
    </>
  );
};

export default Index;
