import { Container } from "@mui/material";
import { Switch, Route } from "react-router-dom";
import Landing from "./pages/Landing/Landing";
import Header from "./components/Header/index";
import Search from "./pages/Search/index";
import "./App.css";

function App() {
  window.document.title = "Crypto App";
  return (
    <>
      <Container maxWidth="lg">
        <Header />
        <Switch>
          <Route path="/search" component={Search} />
          <Route path="/" render={(props) => <Landing {...props} />} />
        </Switch>
      </Container>
    </>
  );
}

export default App;
