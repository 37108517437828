import React from "react";
import { IconButton } from "@mui/material";
import { Link, useLocation, useHistory } from "react-router-dom";
import {
  MagnifyingGlass,
  InstagramLogo,
  ArrowLeft,
  Phone,
  WhatsappLogo,
} from "phosphor-react";
import styles from "./Header.module.scss";

const Index = () => {
  const location = useLocation();
  const history = useHistory();

  const clickHandler = () => {
    history.replace("/");
  };

  return (
    <>
      <div className={styles.Header}>
        {location.pathname === "/" ? (
          <Link to="/search">
            <IconButton
              className={styles.SearchSection}
              aria-label="search"
              size="large"
            >
              <MagnifyingGlass size={24} />
            </IconButton>
          </Link>
        ) : (
          <IconButton
            onClick={clickHandler}
            className={styles.SearchSection}
            aria-label="search"
            size="large"
          >
            <ArrowLeft size={24} />
          </IconButton>
        )}

        <div>
          <IconButton
            className={styles.SearchSection}
            aria-label="search"
            size="large"
            href="https://wa.me/+989120671263"
            sx={{ marginRight: "16px" }}
          >
            <WhatsappLogo size={24} />
          </IconButton>

          <IconButton
            className={styles.SearchSection}
            aria-label="search"
            size="large"
            href="tel:+989120671263"
            sx={{ marginRight: "16px" }}
          >
            <Phone size={24} />
          </IconButton>

          <IconButton
            className={styles.SearchSection}
            aria-label="search"
            size="large"
            href="https://www.instagram.com/aden.uix/"
          >
            <InstagramLogo size={24} />
          </IconButton>
        </div>
      </div>
    </>
  );
};

export default Index;
