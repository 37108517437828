import React, { useEffect, useState } from "react";
import { Skeleton, Button } from "@mui/material";
//
import Card from "../../components/Card/index";
import Shimmer from "../../components/Card/Shimmer";
import SliderCard from "../../components/SliderCard/index";
import styles from "./Landing.module.scss";

// API
import { getCoin } from "../../services/api";

// swiper bundle styles
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";
import "swiper/css";

export const TrendingContext = React.createContext();

const queryTime = [
  {
    id: 1,
    time: "1h",
  },
  {
    id: 2,
    time: "24h",
  },
  {
    id: 3,
    time: "7d",
  },
  {
    id: 4,
    time: "14d",
  },
  {
    id: 5,
    time: "30d",
  },
  {
    id: 6,
    time: "1y",
  },
];

const queryCurrencyArray = [
  { id: 1, value: "usd", symbol: "$" },
  { id: 2, value: "eur", symbol: "€" },
  { id: 3, value: "jpy", symbol: "¥" },
];

const Landing = (props) => {
  window.document.title = "Crypto App";
  const [coins, setCoins] = useState([]);
  const [queryCurrency, setQueryCurrency] = useState(() => {
    const CurrencySaved = localStorage.getItem("Currency");
    const initialValueCurrency = CurrencySaved;
    return initialValueCurrency || "usd";
  });

  const [query, setQuery] = useState(() => {
    const saved = localStorage.getItem("Time");
    const initialValue = saved;
    return initialValue || "1h";
  });

  useEffect(() => {
    const fetchAPI = async () => {
      const data = await getCoin({
        percentage: query,
        Currency: queryCurrency,
      });
      setCoins(data);
    };
    fetchAPI();
  }, [query, queryCurrency]);

  const timeClickHandler = (event) => {
    localStorage.setItem("Time", event.target.name);
    const fetchAPI = async () => {
      const data = await getCoin({
        percentage: query,
        Currency: queryCurrency,
      });
      setCoins(data);
      setQuery(event.target.name);
    };
    fetchAPI();
  };

  const handleChange = (event) => {
    localStorage.setItem("Currency", event.target.value);
    setQueryCurrency(event.target.value);
  };
  return (
    <>
      <h3 className={styles.Titles}>My Portfolio</h3>
      <Swiper
        style={{ marginBottom: "40px" }}
        spaceBetween={20}
        slidesPerView={4}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          375: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          480: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          // when window width is >= 640px
          769: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
        }}
      >
        {coins.length > 0 ? (
          coins.slice(0, 8).map((item) => (
            <SwiperSlide key={item.id}>
              <TrendingContext.Provider value={item}>
                <SliderCard />
              </TrendingContext.Provider>
            </SwiperSlide>
          ))
        ) : (
          <div>
            <SwiperSlide>
              <Skeleton
                variant="rectangular"
                sx={{
                  bgcolor: "grey.900",
                  marginRight: "12px",
                  borderRadius: "12px",
                }}
                height="124px"
                width="100%"
              />
            </SwiperSlide>
            <SwiperSlide>
              <Skeleton
                variant="rectangular"
                sx={{
                  bgcolor: "grey.900",
                  marginRight: "12px",
                  borderRadius: "12px",
                }}
                height="124px"
                width="100%"
              />
            </SwiperSlide>
            <SwiperSlide>
              <Skeleton
                variant="rectangular"
                sx={{
                  bgcolor: "grey.900",
                  marginRight: "12px",
                  borderRadius: "12px",
                }}
                height="124px"
                width="100%"
              />
            </SwiperSlide>
            <SwiperSlide>
              <Skeleton
                variant="rectangular"
                sx={{
                  bgcolor: "grey.900",
                  marginRight: "12px",
                  borderRadius: "12px",
                }}
                height="124px"
                width="100%"
              />
            </SwiperSlide>
          </div>
        )}
      </Swiper>

      <div className={styles.TrendingMarket}>
        <h3>Trending Market</h3>
        <select
          className={styles.Select}
          value={queryCurrency}
          label="Currency"
          onChange={handleChange}
        >
          {queryCurrencyArray.map((curr) => (
            <option key={curr.id} value={curr.value}>
              {curr.value.toLocaleUpperCase()}
            </option>
          ))}
        </select>
      </div>
      <Swiper
        style={{ marginBottom: "40px" }}
        spaceBetween={20}
        slidesPerView={4}
        breakpoints={{
          320: {
            slidesPerView: 4,
            spaceBetween: 2,
          },
          375: {
            slidesPerView: 5,
            spaceBetween: 2,
          },
          480: {
            slidesPerView: 5,
            spaceBetween: 2,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 10,
            spaceBetween: 2,
          },
          // when window width is >= 640px
          769: {
            slidesPerView: 14,
            spaceBetween: 2,
          },
        }}
      >
        {queryTime.map((item) => (
          <SwiperSlide key={item.id}>
            <div>
              <Button
                onClick={timeClickHandler}
                name={item.time}
                className={`${styles.tagtime} ${
                  item.time === query ? styles.tagtime_active : ""
                }`}
              >
                {item.time}
              </Button>
              {/* <Link className={styles.tagtime}>Hello</Link> */}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      {coins.length > 0 ? (
        coins.slice(0, 11).map((coin) => <Card key={coin.id} data={coin} />)
      ) : (
        <div>
          <Shimmer />
          <Shimmer />
          <Shimmer />
          <Shimmer />
          <Shimmer />
        </div>
      )}
    </>
  );
};

export default Landing;
